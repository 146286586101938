import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addProduct } from "../../../redux/Cart/cart.actions";
import "./../productResults.css"
import Buttons from "./../../../components/forms/Button";

const Product = (product) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { 
        documentID,
        productThumbnail, 
        productName, 
        productPrice,
        size
    } = product;
    if(!documentID || !productThumbnail || !productName || typeof productPrice === "undefined") return null;

    const configAddToCartBtn = {
        type: "button"
    }

    const handleAddToCart = (product) => {
        if(!product) return null;

        dispatch(
            addProduct(product)
        );

        history.push("/cart");
    }

    return (
        <div className="product">
            {/* <div className="thumb"> */}
               <Link to={`/product/${documentID}`}> <img src={productThumbnail} alt={productName} /> </Link>
            {/* </div> */}

            {/* <div className="details"> */}
                <ul className="bottom">
                    <div className="details">
                    <li> <span className="name" > <Link to={`/product/${documentID}`}>{productName} </Link> </span> </li>
                    <li>  <span className="size"> Size: {size} </span></li>
                    <li> <span className="price" > ${productPrice} </span> </li>
                    </div>
                    <li> <Buttons  {...configAddToCartBtn} onClick={() => handleAddToCart(product)}> Add to cart </Buttons></li>
                </ul>
            {/* </div> */}
        </div>
    )
}

export default Product;