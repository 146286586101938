import React from "react";
import { Link } from "react-router-dom";

export default function Policy(){
    return (
        <div> 
            
           <div className="policy-div">
                <div className="shippingPolicy-div"> 
                    <div className="policy-background" >
                    <div>
                        <h1> SHIPPING, RETURNS & EXCHANGES </h1> 
                        <p className="policy-background-p"> NO RETURNS OR EXCHANGES ON SNEAKERS </p>
                    </div>
                    </div>

                    <h2> SHIPPING INFORMATION</h2> 

                    <div className="question-div">
                    <p className="question"> Do you ship internationally? </p> 
                    <p className="answer" > • No, we currently do not offer international shipping but we are working to include this option. </p>
                    </div> 
                     
                    <div className="question-div"> 
                    <p className="question"> Do we offer FREE shipping? </p>
                    <p className="answer"> • Yes</p> 
                    </div>


                    <div className="question-div">
                    <p className="question"> How long does it take to process an order?  </p>  
                    <p className="answer"> • Please allow 1-2 days for your order to be processed for shipping.</p>
                    </div> 

                    <div className="question-div">
                    <p className="question"> When will my order ship? </p> 
                    <p className="answer"> • Orders will be shipped throughout the week Monday - Saturday before 5pm. If your order is placed before 12pm your order will be processed and shipped out the following day. If your order is placed after 12pm your order will be processed and shipped within 2 business days. </p>
                    </div> 

                    <div className="question-div">
                    <p className="question"> How can I check the status of my order? </p> 
                    <p className="answer"> • To view your order details and its current status you can head over to the  <span style={{textDecoration: "underline", fontWeight: "400"}}> <Link to="/dashboard"> Order Details  </Link> </span> Page and view your order. </p>
                    </div>

                </div>

                <div className="returnPolicy-div">
                    <h2> RETURN & EXCHANGES</h2>
                    <div className="question-div">
                    <p className="question"> Do you accept returns or exchanges? </p>
                     <p className="answer"> • We can only accept returns/exchanges for reasons of our fault, i.e wrong size sent, damaged product. Items must be unworm with original tags and packaging. Notify us within 7 days of recieving your package. </p> 
                     <p className="answer"> • NO RETURNS OR EXCHANGES ON SNEAKERS.  </p>
                    </div>
                </div>

                <div>
                    <p > If you have any other questions in regards to shipping reach out to us at <span style={{color: "green"}}> support@incomingheat.com </span> </p>
                </div>
            </div>
        </div>
    )
}