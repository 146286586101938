import { firestore } from "./../../firebase/utils";

export const handleAddProduct = product => {
    return new Promise((resolve, reject) => {
        firestore
        .collection("products")
        .doc()
        .set(product)
        .then(() => {
            resolve();
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const handleFetchProducts = ({ filterType, startAfterDoc, persistProducts=[] }) => {
    return new Promise((resolve, reject) => {
        const pageSize = 6;

    let ref =  firestore.collection("products").orderBy("createdDate").limit(pageSize);

    if (filterType) ref = ref.where("productCategory", "==", filterType);
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
       
        ref
        .get()
        .then(snapshot => {
            const totalCount = snapshot.size;

            const data = [
                ...persistProducts,
                ...snapshot.docs.map( doc => {
                    return {
                        ...doc.data(),
                        documentID: doc.id
                    }
                })
            ];

            resolve({
                data,
                queryDoc: snapshot.docs[totalCount - 1],
                isLastPage: totalCount < pageSize
            });
        })
        .catch(err => {
            reject(err);
        })
    })
}

export const handleDeleteProduct = documentID => {
    return new Promise((resolve, reject) => {
        firestore
        .collection("products")
        .doc(documentID)
        .delete()
        .then(() => {
            resolve();
        })
        .catch((err) => {
            reject(err);
        })
    });
}

export const handleFetchProduct= productID => {
    return new Promise((resolve, reject) => {
        firestore
        .collection("products")
        .doc(productID)
        .get()
        .then(snapshot => {
            if(snapshot.exists){
                resolve({
                    ...snapshot.data(),
                    documentID: productID
            });
            }
        })
        .catch(err => {
            reject(err);
        })
    })
}

// export const handleUpdateProductInventory = order => {
//     return new Promise ((resolve, reject) => {
//         const { orderItems } = order;

//         orderItems.map((item) => {
//             const qty = firestore.collection("products").doc(item.docuemntID).get("inventory");
//             firestore
//             .collection("products")
//             .doc(item.documentID)
//             .update({inventory: (qty - item.quanity)})
//             .then(() => {
//                 resolve();
//             })
//             .catch((err) => {
//                 reject(err);
//             })
//         })
       
//     });
// }