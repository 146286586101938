import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCartItems, selectCartTotal } from "./../../redux/Cart/cart.selectors"
import { createStructuredSelector } from "reselect";
import "./checkout.css";

import Buttons from "./../forms/Button";
import Item from "./Item/item";

const mapState = createStructuredSelector({
    cartItems: selectCartItems,
    total: selectCartTotal
});

const Checkout = () => {
    const { cartItems, total } = useSelector(mapState);
    const history = useHistory();
    const errMsg = "You have no items in your cart. ";

    return (
        <div className="checkout">
            <h1> Checkout </h1>

            <div className="cart">
                {cartItems.length> 0? (
                <table>
                    <tbody>

                        <tr>
                            <table className="checkoutHeader">
                                <tbody>
                                    <tr>
                                        <th> Product </th>
                                        <th> Description </th>
                                        <th> Quantity </th>
                                        <th> Price </th>
                                        <th> Remove </th>
                                    </tr>
                                </tbody>
                            </table>
                        </tr>

                        <tr>
                            <table>
                                <tbody>
                                    {cartItems.map((item,pos) => {
                                        return (
                                            <tr key={pos}>
                                                <td>
                                                    <Item {...item}/>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </tr>

                        <tr>
                            <table align="right" >
                                <tr >
                                    <td>
                                        <h3> Total: ${total} </h3>
                                    </td>
                                </tr>
                                <tr>
                                    <table >
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Buttons onClick={() => history.push("/shop")}> Continue Shopping </Buttons>
                                                </td>
                                                <td>
                                                    <Buttons onClick={() => history.push("/payment")}> Checkout </Buttons>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </tr>
                            </table>
                        </tr>
                    </tbody>
                </table>
                ) : (
                    <p> 
                        {errMsg}
                    </p>
                )}
            </div>
        </div>
    )
}

export default Checkout;