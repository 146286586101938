import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOutUserStart } from "./../redux/User/user.actions";

import Nav1 from "./../components/Nav1";
import Nav2 from "../components/Nav2";
import HorizontalNav from "./../components/HorizontalNav/HorizontalNav";

const DashboardLayout = props => {
    const dispatch = useDispatch();

    const signOut = () => {
        dispatch(signOutUserStart());
    };

    return (
        <div className="dashboardLayout">
            <Nav1 {...props}/>
            <Nav2 {...props}/>
            <div>
                <div>
                    <HorizontalNav>
                    <ul>
                        <li> <Link to="/dashboard"> Home </Link></li>
                        <li><span className="signOut" onClick={() => signOut()}> Sign Out</span></li>
                    </ul>
                    </HorizontalNav>
                 </div>
                 <div className="content">
                     {props.children}
                 </div>
            </div>
        </div>
    )
}

export default DashboardLayout;