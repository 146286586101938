import React from "react";
import "./forms.css";

const Buttons = ({ children, ...otherProps}) => {
    return (
        <button className="btn" {...otherProps}>
            {children}
        </button>
    )
}

export default Buttons;
