import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom"
import { fetchProductsStart } from "../../redux/Products/products.actions";

import Product from "./Product/product";
import FormSelect from "../forms/FormSelect/formSelect";

import "./productResults.css";
import LoadMore from "../LoadMore/loadmore";

const mapState = ({ productsData }) => ({
    products: productsData.products
});

const ProductResults = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { filterType } = useParams();
    const { products } = useSelector(mapState);

    const { data, queryDoc, isLastPage } = products;

    useEffect(() => {
        dispatch(
            fetchProductsStart({ filterType })
        )
    }, [ filterType ]);

    const handleFilter = (e) => {
        const nextFilter = e.target.value;
        history.push(`/shop/${nextFilter}`);
    };

    const configFilters = {
        defaultValue: filterType,
        options: [{
            name:"Show all",
            value: ""
        }, {
            name: "Mens",
            value: "mens"
        }, {
            name: "Womens",
            value: "womens"
        },{
            name: "Apparel",
            value: "apparel"
        },{
            name: "Collectibles",
            value: "collectibles"
        },
        {
            name: "Accessories",
            value: "accessories"
        }], 
        handleChange: handleFilter
    }

    if(!Array.isArray(data)) return null;

    if(data.length < 1){
        return (
            <div className="products">
                <FormSelect {...configFilters}/>
                <p> No results found. </p>
            </div>
        );
    }


    const handleLoadMore = () => {
        dispatch(
            fetchProductsStart({ 
                filterType, 
                startAfterDoc: queryDoc,
                persistProducts: data
            })
        )
    }

    const configLoadMore = {
        onLoadMoreEvt: handleLoadMore,
    }

    return (
        <div className="products">

            <h1> 
                Browse Products 
            </h1>

            <FormSelect {...configFilters}/>

            <div className="products-div">

            {data.map((product, pos) => {
                const { productThumbnail, productPrice, productName, inventory} = product;
                if(!productThumbnail || !productName || typeof productPrice === "undefined" || parseInt(inventory) <= 0) return null;

                const configProduct = {
                    ...product
                }

                return (
                   
                    <Product key={pos} {...configProduct} />

                )
            })}
            </div>

            {!isLastPage && (
                <LoadMore {...configLoadMore}/>
            )}

        </div>
    )
}

export default ProductResults;