import axios from "axios";

export const checkUserIsAdmin = currentUser => {
    if(!currentUser || !Array.isArray(currentUser.userRoles)) return false;
    const { userRoles } = currentUser; 
    if(userRoles.includes("admin")) return true;

    return false;
}

export const apiInstance = axios.create({
     //baseURL: "http://localhost:5001/incoming-heat/us-central1/api",
     baseURL: "https://us-central1-incoming-heat.cloudfunctions.net/api"
});

