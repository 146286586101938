import React from "react";
import SignIn from "../components/SignIn/SignIn"
import "./../App.css";

export default function Login(){
    return (
        <div className="format-div">
            <SignIn/>
        </div>
    )
}