import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductStart, setProduct, } from "./../../redux/Products/products.actions";
import { addProduct } from "../../redux/Cart/cart.actions";
import Buttons from "../forms/Button";
import "./productCard.css";

const mapState = state => ({
    product: state.productsData.product
});

const ProductCard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { productID } = useParams();
    const { product } = useSelector(mapState);

    const {
        productName,
        productThumbnail,
        productPrice,
        productDesc,
    } = product;

    useEffect(()=> {
       dispatch(
           fetchProductStart(productID)
       ) 

       return () => {
           dispatch(
               setProduct({})
           )
       }
    }, []);

    const configAddToCartBtn = {
        type: "button"
    }

    const handleAddToCart = product => {
        if(!product) return null;

        dispatch(
            addProduct(product)
        );

        history.push("/cart");
    }

    return (
        <div className="containerDiv">
            <div className="product-card">
                <div className="hero">
                    <img src={productThumbnail} alt={productName} />
                </div>

                <div className="productDetails">
                    <ul>
                        <li> <h1> {productName} </h1></li>
                        {/* <li> {productSize} </li> */}
                        <li> <span> ${productPrice} </span></li>
                        <li> <div className="addToCart"> <Buttons {...configAddToCartBtn} onClick={() => handleAddToCart(product)}> Add to Cart </Buttons> </div></li>
                        <li> 
                            <span
                                dangerouslySetInnerHTML={{__html: productDesc}} />
                            
                         </li>
                    </ul>
                </div>

            </div>
        </div>
    )
}

export default ProductCard;