// import {initializeApp} from "firebase/app";
// import {getFirestore} from "firebase/firestore";
// import "firebase/auth"; 

export const firebaseConfig = {
  apiKey: "AIzaSyBf9bOQpnBdHJwwFsLVmkqezEm0Ah6Dgrs",
  authDomain: "incoming-heat.firebaseapp.com",
  databaseURL: "https://incoming-heat-default-rtdb.firebaseio.com",
  projectId: "incoming-heat",
  storageBucket: "incoming-heat.appspot.com",
  messagingSenderId: "437692507394",
  appId: "1:437692507394:web:12af01d097b5e2642c0e1b",
  measurementId: "G-X4CNHQ6MWV"
  };


// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);

// export default db;