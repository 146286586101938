const productTypes = {
    ADD_NEW_PRODUCT_START: "ADD_NEW_PRODUCT_START",
    FETCH_PRODUCTS_START: "FETCH_PRODUCTS_START",
    SET_PRODUCTS: "SET_PRODUCTS",
    DELETE_PRODUCT_START: "DELETE_PRODUCT_START",
    FETCH_PRODUCT_START: "FETCH_PRODUCT_START",
    SET_PRODUCT: "SET_PRODUCT",
    //UPDATE_PRODUCT_INVENTORY: "UPDATE_PRODUCT_INVENTORY",
    
};

export default productTypes;