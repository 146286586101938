import React from "react";
import "./../App.css";

import EmailPassword from "../components/EmailPassword/EmailPassword";

const Recovery = props => {
    return (
        <div className="format-div">
            <EmailPassword/>
        </div>
    );
}

export default Recovery;