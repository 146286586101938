import React from "react";
import Nav1 from "../components/Nav1";
import Nav2 from "../components/Nav2";

const MainLayout = props => {
    return (
        <div>
            <Nav1/>
            <Nav2 {...props}/>
            <div>
                {props.children}
            </div>
        </div>
    )
};

export default MainLayout;