import React from "react";
import { useSelector } from "react-redux";
import UserProfile from "../UserProfile/UserProfile";
import "./horizontalNav.css"

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const HorizontalNav = ({ children }) => {
    const { currentUser } = useSelector(mapState);

    const configUserProfile = {
        currentUser
    }

    return (
        <div className="horizontalNav">
            <UserProfile {...configUserProfile}/>

            <div className="menu">
                {children}
            </div>
        </div>
    )
}

export default HorizontalNav;