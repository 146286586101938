import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOutUserStart} from "./../redux/User/user.actions";

import Nav1 from "../components/Nav1";
import Nav2 from "../components/Nav2";
import HorizontalNav from "../components/HorizontalNav/HorizontalNav";

const AdminLayout = props => {
    const dispatch = useDispatch();

    const signOut = () => {
        dispatch(signOutUserStart());
    };

    return (
        <div className="adminLayout">
            <Nav1 {...props}/>
            <Nav2 {...props}/>
             <div className="controlPanel"> 
                <div className="sidebar">
                    <HorizontalNav>
                        <ul>
                            <li><Link to="/admin"> Home </Link></li>
                            <li><span className="signout" onClick={() => signOut()}> Signout </span></li>
                        </ul>
                    </HorizontalNav>
                </div>
                <div className="content">
                    {props.children}
                </div>
             </div>
        </div>
    )


}

export default AdminLayout;