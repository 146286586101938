import React from "react";
import "./formSelect.css";

const FormSelect = ({ options, defaultValue, handleChange, label, ...otherProps}) => {
    if (!Array.isArray(options) || options.lenght < 1) return null;

    return (
        <div className="formRow">
            {label && (
                <label>
                    {label}
                </label>
            )}

            <select className="formSelect" value={defaultValue} onChange={handleChange} {...otherProps}>
                {options.map((option, index) => {
                    const { value, name } = option;

                    return (
                        <option key={index} value={value}> {name} </option>
                    );
                })}
            </select>
        </div>
    )
}

export default FormSelect;