import React from "react";
import { useHistory} from "react-router-dom";

export default function Home(){
  const history = useHistory();

    return (
        <div> 
             {/* home layout  */}
             
            <div className="home-layout" >
            {/* shop sneaker div */}
            
            <div className="sneaker-div shrink" onClick={() => history.push("/shop")}>
               <h1> SHOP SNEAKERS </h1> 
             </div> 
             

             {/* shop-apparel-div */}
            <div className="apparel-div shrink" onClick={() => history.push("/shop/apparel")} >  
              <h1> SHOP APPAREL </h1>
            </div>

            {/* shop collectibles-div */}
            <div className="collectible-div shrink" onClick={() => history.push("/shop/collectibles")}>  
              <h1> SHOP COLLECTIBLES </h1>
            </div>   

            {/* shop-totes-div */}
            <div className="tote-div shrink" onClick={() => history.push("/shop/accessories")}> 
            <div className="tote-inner-div" > 
            <h1 style={{position: "absolute"}}> SHOP TOTES </h1>
            </div>
            </div>
            </div>
        </div>
    )
}