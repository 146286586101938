import React from "react";
import { Link } from "react-router-dom";
export default function Contact(){
    return (
        <div>
            <div className="contact-div"> 
                <div  className="contact-background" >
                <div>
                <h1> CONTACT US </h1>
                <p className="contact-background-p"  > We are currently based out of Charlotte and surrounding areas.  </p>
                </div>
                </div>

                <div className="contact-div-content" >
                <h2 className="contact-div-heading"> We would love to hear from you!</h2> 
                <p> • Instagram: @incoming_heat </p>
                </div> 

                <div className="contact-div-content">
                <h2 className="contact-div-heading" > Customer Service:  </h2>
                <p> • We respond to all emails within 48hrs. </p>
                <p> • Send us an email at support@incomingheat.com for any questions regarding our website or orders. </p>
                <p> • For ALL other inquiries feel free to message us in the incoming heat DM</p>
                <Link to="/dashboard"> • Order Details/Tracking </Link> 
                </div>
            </div>
        </div>
    )
}