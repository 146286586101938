import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {BrowserRouter as Router, Switch, Route } from "react-router-dom"; // React router dom - routing 
import './App.css';
import { checkUserSession } from "./redux/User/user.actions"

//pages
import Home from "./pages/Home";
import Policy from "./pages/Policy";
import Contact from "./pages/Contact";
import MyOrder from "./pages/MyOrder";
import Shop from "./pages/Shop/Shop";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Recovery from "./pages/Recovery";
import Dashboard from "./pages/Dashboard/Dashboard";
import Admin from "./pages/Admin/Admin";
import Cart from "./pages/Cart/cart";
import Payment from "./pages/Payment/payment";
import Order from "./pages/Order/order"

//higher order component hoc 
import WithAuth from "./hoc/WithAuth";
import WithAdminAuth from "./hoc/withAdminAuth";

//components
import AdminToolbar from "./components/AdminToolbar/AdminToolbar";


//layouts
import MainLayout from "./layouts/MainLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import AdminLayout from "./layouts/AdminLayout";
import ProductDetails from "./pages/ProductDetails/productDetails";

// Class component 
const App = props => {
  const dispatch = useDispatch();

  useEffect(() => {
   dispatch(checkUserSession());
   
  },[])

    return (
      <Router>
        <Switch> 
      <div>
        <AdminToolbar/>
        <Route path="/" exact render={() => (
          <MainLayout >
            <Home/>
          </MainLayout>
        )}/> 
        <Route path="/shop" exact render={() => (
          <MainLayout>
            <Shop/>
          </MainLayout>
        )} />
        <Route path="/shop/:filterType"  render={() => (
          <MainLayout>
            <Shop/>
          </MainLayout>
        )} />
        <Route path="/product/:productID"  render={() => (
          <MainLayout>
            <ProductDetails/>
          </MainLayout>
        )} />
        <Route path="/cart"  render={() => (
          <MainLayout>
            <Cart/>
          </MainLayout>
        )} />
        <Route path="/payment"  render={() => (
            <WithAuth>
              <MainLayout>
              <Payment/>  
              </MainLayout>
            </WithAuth>
        )} />
         <Route path="/order/:orderID"  render={() => (
            <WithAuth>
              <DashboardLayout>
              <Order/>  
              </DashboardLayout>
            </WithAuth>
        )} />
        <Route path="/policy" exact render={() => (
         <MainLayout>
           <Policy/>
         </MainLayout>
          )}/>
        <Route path="/contact" exact render={() => (
          <MainLayout>
            <Contact/>
          </MainLayout>
        )}/>
        <Route path="/myorder" exact component={MyOrder}/>
        <Route path="/registration" exact 
        render = {() => (
          <MainLayout > 
            <Registration/>
          </MainLayout>
        )
          }/> 
        <Route path="/login" exact 
          render={() =>  (
            <MainLayout >
              <Login/>
            </MainLayout>
          )}/>
          <Route path="/recovery" exact render={() => (
            <MainLayout>
              <Recovery/>
            </MainLayout>
          )}/>
          <Route path="/dashboard" exact render={() => (
            <WithAuth>
                  <DashboardLayout>
                     <Dashboard/>
                  </DashboardLayout> 
            </WithAuth>
          )}/>
          <Route path="/admin" exact render={() => (
            <WithAdminAuth>
                 <AdminLayout>
                   <Admin/>
                 </AdminLayout>
            </WithAdminAuth>
             
          )}/>
      </div>
        </Switch>
      </Router>
    );

  }

export default App;
