import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faBars, faHome, faShoppingBag, faShoppingCart, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import {Link} from "react-router-dom";
import React, { useState } from 'react';

export default function Nav1(){
    const [ isOpen, setIsOpen] = useState(false);
    const toggle = () =>  setIsOpen(!isOpen);

    function openNav(){
        const menu = document.getElementById("bars");
        const mobileOverlay = document.getElementById("mobile-overlay");
        const cart = document.getElementById("cart-icon");

        if(isOpen === false){
            toggle();
            
            mobileOverlay.classList.add("active");
            menu.style.display = "none";
            cart.style.display = "none";
        }else {
            mobileOverlay.classList.remove("active");
            menu.style.display = "block";
            cart.style.display = "block";
            toggle();
        }
    }
    
    return (
        
        <div id="nav">
              {/* Side Navigation Bar   */}
    <div className="nav">
      <div > <img alt="#"  style={{width: "90%"}} src="../ihwhite.png"/> </div>
      <div className="links-div"> 
        <Link to="/" className="active"> <FontAwesomeIcon icon={faHome} /> Home </Link>
        <Link to="/Shop"> <FontAwesomeIcon icon={faShoppingBag} /> Shop </Link>
        <Link to="/cart"> <FontAwesomeIcon icon={faAsterisk}/> My Order</Link>
      </div>

      <div className="links-div2">
            <Link to="/policy"> Policy </Link> 
            <Link to="/contact"> Contact </Link>
            <a href="https://www.instagram.com/incoming_heat/" target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faInstagram}/> </a>
      </div>
    </div>

    <div id="mobileNav" className="mobile-nav"> 
        <FontAwesomeIcon onClick={openNav} id="bars" className="fa-2x" icon={faBars}/>
        <h1> INCOMING HEAT </h1>
       <Link to="/cart"> <FontAwesomeIcon id="cart-icon" className="fa-2x" color="white" icon={faShoppingCart}/> </Link>
    </div>

    <div id="mobile-overlay" className="mobile-nav-overlay"> 
        <div className="mobile-nav-overlay-content">
        <div className="links-div"> 
        <FontAwesomeIcon onClick={openNav} id="close-btn" className="fa-2x" style={{position: "absolute", top: "2%", right: "2%"}}icon={faTimes}/>
        <Link onClick={openNav} to="/"className="active"> <FontAwesomeIcon icon={faHome} /> Home </Link>
        <Link to="/shop"> <FontAwesomeIcon icon={faShoppingBag} /> Shop </Link>
        <Link onClick={openNav} to="/dashboard"> <FontAwesomeIcon icon={faAsterisk}/> My Order</Link>
      </div>

      <div className="links-div2">
            <Link onClick={openNav} to="/policy"> Policy </Link> 
            <Link onClick={openNav} to="/contact"> Contact </Link>
            <a onClick={openNav} href="https://www.instagram.com/incoming_heat/" target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon icon={faInstagram}/> </a>
      </div>
         </div>
    </div>
        </div>
    )
}