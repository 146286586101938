import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements, } from "@stripe/react-stripe-js";
import  FormInput  from "./../forms/FormInput";
import Button  from "./../forms/Button";
import { useHistory } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector";
import { apiInstance } from "./../../Utils/utils"
import { selectCartTotal, selectCartItemsCount, selectCartItems } from "./../../redux/Cart/cart.selectors";
import { saveOrderHistory } from "./../../redux/Orders/orders.actions"
import { createStructuredSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import "./paymentDetails.css"
// import { updateProductInventory } from "../../redux/Products/products.actions";
import { firestore } from "./../../firebase/utils";
import { deleteProductStart, } from "./../../redux/Products/products.actions";


const initialAddressState = {
    line1: "",
    line2: "",
    city: "",
    state: "",
    postal_code: "",
    country: ""
};

const mapState = createStructuredSelector({
    total: selectCartTotal,
    itemCount: selectCartItemsCount,
    cartItems: selectCartItems
})

const mapState1 = ({ user }) => ({
    currentUser: user.currentUser
})

const PaymentDetails = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { total, itemCount, cartItems } = useSelector(mapState);
    const { currentUser } = useSelector(mapState1);
    const dispatch = useDispatch();
    const history = useHistory();
    const [billingAddress, setBillingAddress] = useState({...initialAddressState});
    const [shippingAddress, setShippingAddress] = useState({...initialAddressState});
    const [recipientName,setRecipientName] = useState("");
    const [nameOnCard,setNameOnCard] = useState("");

    useEffect(() => {
        if(itemCount < 1){
            history.push("/dashboard");
        }
    }, [itemCount])

    const handleShipping = (evt) => {
        const { name, value } = evt.target;
        setShippingAddress({
            ...shippingAddress,
            [name]: value
        });
    }

    const handleBilling = (evt) => {
        const { name, value } = evt.target;
        setBillingAddress({
            ...billingAddress,
            [name]: value
        });
    }

    const handleFormSubmit = async evt => {
        evt.preventDefault();
        const CardElement = elements.getElement("card");

        if(
            !shippingAddress.line1 || !shippingAddress.city ||
            !shippingAddress.state || !shippingAddress.postal_code || 
            !shippingAddress.country || !billingAddress.line1 ||
            !billingAddress.city || !billingAddress.state ||
            !billingAddress.postal_code || !billingAddress.country ||
            !recipientName || !nameOnCard
        ) {
            return null;
        }

        apiInstance.post("/payments/create", {
            amount: total * 100,
            shipping: {
                name: recipientName,
                address: {
                    ...shippingAddress
                }
            }
        }).then(({ data: clientSecret}) => {
            stripe.createPaymentMethod({
                type: "card",
                card: CardElement,
                billing_details: {
                    name: nameOnCard,
                    address: {
                        ...billingAddress
                    }
                }
            }).then(({paymentMethod}) => {
                stripe.confirmCardPayment(clientSecret, {
                    payment_method: paymentMethod.id
                })
                .then(({ paymentIntent }) => {

                    const configOrder = {
                        orderTotal: total,
                        userName: recipientName,
                        userEmail: currentUser.email,
                        shipAddress: {...shippingAddress},
                        orderItems: cartItems.map(item => {
                            const { documentID, productThumbnail, productName, productPrice, quantity,} = item;
                            
                         
                            return {
                                documentID,
                                productThumbnail,
                                productName, 
                                productPrice,
                                quantity
                            }
                        })
                    }

                     cartItems.map(item => {
                        const { documentID, quantity} = item;

                       // const doc = firestore.collection("products").doc(documentID).get();
                        async function getDoc(id) {
                            const snapshot = await firestore.collection('products').doc(id).get();
                            const data = snapshot.data();
                            console.log(data.inventory * 2);

                            firestore.collection('products').doc(id).update({ inventory: (data.inventory - quantity)});

                            const snapshot1 = await firestore.collection('products').doc(id).get();
                            const data1 = snapshot1.data();
                            console.log(data1.inventory);
                            if(parseInt(data1.inventory) === 0){
                                dispatch(
                                    deleteProductStart(id)
                                )
                            }
                        }
                        
                    
                        return getDoc(documentID);
                    });

                   dispatch(
                       saveOrderHistory(configOrder),
                       //updateProductInventory(configOrder)
                   );
                })
            })
        });

    };

    const configCardElement = {
        iconStyle: "solid",
        style: {
            base: {
                fontSize: "16px"
            }
        },
        hidePostalCode: true
    };

    return (
        <div className="paymentDetails">
            <form onSubmit={handleFormSubmit}> 

                <div className="group">
                    <h2> Shipping Address </h2>

                    <FormInput
                        required
                        placeHolder="Recipent Name"
                        name="recipientName"
                        handleChange={evt => setRecipientName(evt.target.value)}
                        type="text"
                        value={recipientName}
                    />

                    <FormInput
                        required
                        placeHolder="Line 1"
                        name="line1"
                        handleChange={evt => handleShipping(evt)}
                        type="text"
                        value={shippingAddress.line1}
                    />

                    <FormInput
                        placeHolder="Line 2"
                        name="line2"
                        handleChange={evt => handleShipping(evt)}
                        type="text"
                        value={shippingAddress.line2}
                    />

                    <FormInput
                        required
                        placeHolder="City"
                        name="city"
                        handleChange={evt => handleShipping(evt)}
                        type="text"
                        value={shippingAddress.city}
                    />

                    <FormInput 
                        required
                        placeHolder="State"
                        name="state"
                        handleChange={evt => handleShipping(evt)}
                        type="text"
                        value={shippingAddress.state}
                    />

                    <FormInput 
                        required
                        placeHolder="Postal Code"
                        name="postal_code"
                        handleChange={evt => handleShipping(evt)}
                        type="text"
                        value={shippingAddress.postal_code}
                    />

                    <div className="formRow checkoutInput">
                    <CountryDropdown 
                        required
                        valueType="short"
                        onChange={val => handleShipping({
                            target: {
                                name: "country",
                                value: val
                            }
                        })}
                        value={shippingAddress.country}
                    />
                </div>


                </div>

                <div className="group">
                    <h2> Billing Address </h2>

                    <FormInput
                        required
                        placeHolder="Name on Card"
                        name="nameOnCard"
                        handleChange={evt => setNameOnCard(evt.target.value)}
                        type="text"
                        value={nameOnCard}
                    />

                    <FormInput
                        required
                        placeHolder="Line 1"
                        name="line1"
                        handleChange={evt => handleBilling(evt)}
                        type="text"
                        value={billingAddress.line1}
                    />

                    <FormInput
                        placeHolder="Line 2"
                        name="line2"
                        handleChange={evt => handleBilling(evt)}
                        type="text"
                        value={billingAddress.line2}
                    />

                    <FormInput
                        required
                        placeHolder="City"
                        name="city"
                        handleChange={evt => handleBilling(evt)}
                        type="text"
                        value={billingAddress.city}
                    />

                    <FormInput 
                        required
                        placeHolder="State"
                        name="state"
                        handleChange={evt => handleBilling(evt)}
                        type="text"
                        value={billingAddress.state}
                    />

                    <FormInput 
                        required
                        placeHolder="Postal Code"
                        name="postal_code"
                        handleChange={evt => handleBilling(evt)}
                        type="text"
                        value={billingAddress.postal_code}
                    />

                <div className="formRow checkoutInput">
                    <CountryDropdown 
                        required
                        valueType="short"
                        onChange={val => handleBilling({
                            target: {
                                name: "country",
                                value: val
                            }
                        })}
                        value={billingAddress.country}
                    />
                </div>

                </div>

                <div className="group">
                    <h2> Card Details </h2>

                    <CardElement 
                        options={configCardElement}
                        />

                    <Button> Pay Now </Button>
                </div>
            </form>
        </div>
    )
}

export default PaymentDetails;