import React from "react";
import ProductCard from "../../components/ProductCard/productCard";

const ProductDetails = () => {
    return (
        <div>
            <ProductCard/>
        </div>
    )
}

export default ProductDetails;