import React from "react";
import PaymemtDetails from "./../../components/PaymentDetails/paymentDetails";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { publishableKey } from "../../stripe/config";

import "./payment.css"

const stripePromise = loadStripe(publishableKey);

const Payment = () => {
    return (
        <div className="payment-div">
            <Elements stripe={stripePromise}>
                <PaymemtDetails/>
            </Elements>
        </div>
    )
}

export default Payment;