import React from "react";
import "./shop.css";

import ProductResults from "../../components/ProductResults/productResults";

const Shop = () => {
    return (
        <div className="shoppage">
           <ProductResults/>
        </div>
    )
}

export default Shop;