import React, {Component} from "react";
import Signup from "../components/SignUp/Signup";
import "./../App.css";

class Registration extends Component {
    render() {
        return (
         <div className="format-div" > 
            <Signup/> 
        </div>

        )
    }
}

export default Registration;