import React from "react";

//Redux
import { useSelector, useDispatch } from "react-redux";

import { signOutUserStart } from "./../redux/User/user.actions";

import { selectCartItemsCount} from "./../redux/Cart/cart.selectors"
import { Link } from 'react-router-dom';


const mapState = (state) => ({
  currentUser: state.user.currentUser,
  totalNumCartItems: selectCartItemsCount(state)
});

const Nav2 = props => {
  const dispatch = useDispatch();
  const { currentUser, totalNumCartItems } = useSelector(mapState);

  const signOut = () => {
    dispatch(signOutUserStart());
  }
    return (
      <header className='header'>
        <div className="wrap">
          
            {/* top navigation featuring search bar and shopping cart */}
            <div className="nav2" >
                  
            <ul>
              <li className="your-cart"> <Link to="/cart"> Your Cart ({totalNumCartItems}) </Link></li>

              {currentUser && [
                <>
               <li key="account"> <Link to="/dashboard"> MY ACCOUNT </Link> </li>
               <li key="logout"> <span onClick={() => signOut()}>
                  LOGOUT
                </span> </li>
                </>
              ]}

              {!currentUser && [
                
                <li key="register"> <Link to="/registration"> REGISTER </Link> </li>,
                <li key="login"> <Link to="/login"> LOGIN </Link> </li>
                
              ]}
              
            </ul>

            {/* search - bar  */}
            {/* <div className="searchBar-div"> 
            <input type="text" placeholder="Search..."/> 
            <button className="search-button"> <FontAwesomeIcon icon={faSearch}/> </button>
            </div> */}

            
            {/* shopping - cart */}
            {/* <div className="shoppingCart-div" >
              <FontAwesomeIcon  className="fa-lg"style={{marginRight: "18%"}} icon={faShoppingCart}/>
              <p style={{fontWeight: "bold", color: "orangered"}}> 1 </p>
            </div> */}

            </div>
        </div> 
        </header>
    )
}
Nav2.defaultProps = {
 currentUser: null
};

export default Nav2;