import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProductStart, deleteProductStart, fetchProductsStart } from "./../../redux/Products/products.actions";


import Buttons from "./../../components/forms/Button";
import Modal from "./../../components/Modal/modal"
import FormInput from "./../../components/forms/FormInput";
import FormSelect from "./../../components/forms/FormSelect/formSelect"
import LoadMore from "../../components/LoadMore/loadmore";

import { CKEditor } from "ckeditor4-react";

import "./admin.css";

const mapState = ({ productsData}) => ({
    products: productsData.products
})
const Admin = props => {
    const { products } = useSelector(mapState);
    const dispatch = useDispatch();
    // const [products, setProducts] = useState([]);
    const [hideModal,setHideModal] = useState(true);
    const [productCategory, setProductCategory] = useState("mens");
    const [productName, setProductName] = useState("");
    const [productThumbnail, setProductThumbnail] = useState("");
    const [productPrice, setProductPrice] = useState(0);
    const [productDesc, setproductDesc] = useState("");
    const [size, setSize] = useState("");
    const [inventory, setInventory] = useState(0);

    const { data, queryDoc, isLastPage } = products;

    useEffect(()=> {
        dispatch(
            fetchProductsStart()
        );
    }, [])

    const toggleModal = () => setHideModal(!hideModal);

    const configModal = {
        hideModal,
        toggleModal
    }

    const resetForm = () => {
        setHideModal(true);
        setProductCategory("");
        setProductName("");
        setProductThumbnail();
        setProductPrice(0);
        setproductDesc(""); 
        setSize("");
        setInventory(0);

    };

    const handleSubmit = e => {
        e.preventDefault();

        dispatch(
            addProductStart({
                productCategory,
                productName,
                productThumbnail,
                productPrice,
                productDesc,
                size,
                inventory
            })
        );

        resetForm();
    };
    const handleLoadMore = () => {
        dispatch(
            fetchProductsStart({
                startAfterDoc: queryDoc,
                persistProducts: data
            })
        );
    }

    const configLoadMore = {
        onLoadMoreEvt: handleLoadMore
    }

    return (
        <div id="admin">
            <h1> MY ADMIN </h1>
        <div className="admin">

            <div className="callToActions">
                <ul>
                    <li>
                        <Buttons onClick={() => toggleModal()}> Add new product </Buttons>
                    </li>
                </ul>
            </div>
            
            
            <Modal {...configModal}>
                <div className="addNewProductForm">
                    <form onSubmit={handleSubmit}>

                        <h2> Add new product </h2>

                        <FormSelect
                            label="Category"
                            options={[{
                                value: "mens",
                                name: "Mens"
                            },{
                                value:"womens",
                                name: "Women"
                            },{
                                value: "apparel",
                                name: "Apparel"
                            }, {
                                name: "Collectibles",
                                value: "collectibles"
                            },{
                                name: "Accessories",
                                value: "accessories"
                            }
                        ]}
                            handleChange={e => setProductCategory(e.target.value)}
                        />

                        <FormInput
                            label="Name"
                            type="text"
                            value={productName}
                            handleChange={e => setProductName(e.target.value)}
                        />

                        <FormInput
                            label="Main image URL"
                            type="url"
                            value={productThumbnail}
                            handleChange={e => setProductThumbnail(e.target.value)}
                        />

                        <FormInput
                            label="Price"
                            type="number"
                            min="0.00"
                            max="10000.00"
                            step="0.01"
                            value={productPrice}
                            handleChange={e => setProductPrice(e.target.value)}
                        />

                        <FormInput
                            label="Size"
                            type="text"
                            value={size}
                            handleChange={e => setSize(e.target.value)}
                        />

                        <FormInput
                            label="Inventory"
                            type="number"
                            min="0"
                            max="100"
                            step="1"
                            value={inventory}
                            handleChange={e => setInventory(e.target.value)}
                        />  

                        <CKEditor onChange={evt => setproductDesc(evt.editor.getData())}/>

                        <br/>

                        <Buttons type="submit"> Add product </Buttons>
                    </form>

                </div>
            </Modal>

            <div className="manageProducts"> 

                <table border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                        <tr>
                            <th>
                                <h1> Manage Products </h1>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <table border="0" cellpadding="10" cellspacing="0">
                                    <tbody>
                                       {(Array.isArray(data) && data.length > 0) && data.map((product,index)=> {
                                           const {
                                               productName,
                                               productThumbnail,
                                               productPrice,
                                               documentID
                                           } = product;
                                           return (
                                            <tr key={index}>
                                                 <td>
                                                    <img className="thumb" src={productThumbnail} alt={productName}/>
                                                 </td>
                                                 <td> {productName} </td>
                                                 <td> ${productPrice} </td>
                                                 <td> 
                                                     <Buttons onClick={() => dispatch(deleteProductStart(documentID))}> 
                                                        Delete
                                                         </Buttons>
                                                 </td>
                                            </tr>
                                       )
                                       })}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>

                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table>
                                    {!isLastPage && (
                                        <LoadMore {...configLoadMore} />
                                    )}
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

               

            </div>
            </div>
        </div>
    );
}

export default Admin;