import productTypes from "./products.types";

const INTITAL_STATE = {
    products: [],
    product: {}
};

function productsReducer(state = INTITAL_STATE, action) {
    switch (action.type) {
        case productTypes.SET_PRODUCTS:
            return {
                ...state,
                products: action.payload
            };
        case productTypes.SET_PRODUCT:
            return {
                ...state,
                product: action.payload
            }
        default:
            return state;
    }
};

export default productsReducer;