import React from "react";

import Checkout from "../../components/Checkout/checkout";
import "./cart.css"

const Cart = () => {
    return (
        <div className="cart-div">
          <Checkout/>
        </div>
    )
}

export default Cart;