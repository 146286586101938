import React, { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Buttons from "../forms/Button";

import { emailSignInStart, googleSignInStart } from  "./../../redux/User/user.actions";

import FormInput from "../forms/FormInput";
import AuthWrapper from "../AuthWrapper/AuthWrapper";


// css import 
import "./SignIn.css";

const mapState = ({ user }) => ({
    currentUser: user.currentUser
});

const SignIn = props => {
    const dispatch = useDispatch();
    const { currentUser } = useSelector(mapState);
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [errors, setErrors] = useState();

    useEffect(() => {
        if(currentUser){
            resetForm();
            history.push("/");
        }
    }, [currentUser])

    const resetForm = () => {
        setEmail("");
        setPassword("");
    }

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(emailSignInStart({ email, password}));
    }

    const handleGoogleSignIn = () => {
        dispatch(googleSignInStart());
    }

    const configAuthWrapper = {
            headline: "Login"
    }

        return (
            <AuthWrapper {...configAuthWrapper}>

                    <div className="formWrap">

                        {/* {errors.length > 0 && (
                            <ul> 
                                {errors.map((err, index) => {
                                    return (
                                        <li className="error" key={index}> {err} </li>
                                    );
                                })}
                            </ul>
                        )} */}

                        <form onSubmit={handleSubmit}> 

                            <FormInput
                                type="email"
                                name="email"
                                value={email}
                                placeholder="Email"
                                handleChange={e => setEmail(e.target.value)}
                            />

                            <FormInput
                                type="password"
                                name="password"
                                value={password}
                                placeholder="Password"
                                handleChange={e => setPassword(e.target.value)}
                            />  

                            <Buttons type="submit">
                                LogIn
                            </Buttons>

                            <div className="socialSignin"> 
                                <div className="row">
                                    <Buttons onClick={handleGoogleSignIn}>
                                        Sign in with Google 
                                    </Buttons>
                                </div>
                            </div>

                            <div className="links"> 
                                <Link to="/recovery">
                                    Reset Password
                                </Link>
                            </div>
                        </form>
                    </div>
            </AuthWrapper>  
        );
    }


export default SignIn; 