import React from "react";
export default function MyOrder(){
    return (
        <div> 
            <div  className="myOrder" >
                <h1 style={{fontSize: "270%"}}> MY ORDER </h1>
                <input style={{ padding: "1% 2%", width: "30%", marginTop:"1%"}} placeholder="search by order#..."/> 

                <div style={{display: "flex", border: "1px solid black", justifyContent:"space-between", padding: ".5% 1% 1% 1%"}}>
                

                <div>
                    <h3 style={{margin: "0% 0% 10% 0%"}}> Order #: XCHSFHJF8729KS9 </h3>
                    <h3> Order Status: <span style={{color: "green"}}> DELIVERED </span> </h3>
                    <button style={{padding: "2.5% 3.5%", marginTop: "10%", fontSize: "120%", color: "whitesmoke", backgroundColor: "orangered", border: "none", borderRadius: "6px"}}> Track My Order </button>
                </div>

                <div> 
                    <h3> Shipment Details: </h3>
                    <p> Product Name </p>
                    <img  alt="" style={{width: "180px", height: "180px" }}/>
                    <p> Qty: </p>
                    <p> Size: </p>
                </div>
                </div>
    

                <div style={{display: "flex", border: "1px solid black", alignItems: "start", justifyContent: "space-between", padding: "2%"}}>
                <div>
                    <h3> Order Details: </h3>
                    <p> Order Date: </p>
                    <p> Order Total: </p>
                </div>

                <div> 
                    <h3> Shipping Address: </h3>
                    <p> Name </p>
                    <p> Street </p>
                    <p> City, State Zip</p>
                    <p> Country </p>
                </div>

                <div> 
                    <h3> Payment Details: </h3>
                    <p> Payment Method </p>
                    <p> Billing Address </p>
                </div>
                </div>
            </div>
        </div>
    )
}